"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("./base");
require("./../stylesheets/landing-style.less");
var $ = require("jquery");
$(function () {
    $("nav a").on("click", function (event) {
        if (this.hash !== "") {
            event.preventDefault();
            var hash = this.hash;
            $("html, body").animate({
                scrollTop: $(hash).offset().top,
            }, 500, function () {
                window.location.hash = hash;
            });
        }
    });
    var lang = $("html").attr("lang");
    document.getElementById("form-button").onclick = function () {
        location.href =
            "form.html?lng=" + lang + window.location.search.replace("?", "&");
    };
    document
        .getElementById("form-link")
        .setAttribute("href", "form.html?lng=" + lang + window.location.search.replace("?", "&"));
    document
        .getElementById("lang")
        .setAttribute("href", "index" + (lang === "es" ? "_cat" : "") + ".html" + window.location.search);
});
